<template>
<section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">In vendita al privato </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Crea Preventivo</b-breadcrumb-item>
            <b-breadcrumb-item active> Seleziona Cliente</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <b-row class="align-items-center">
            <b-col md="8">
               
                <b-form-group label="Nome del cliente">
                    <div class="d-flex">
                    <b-form-input placeholder="Digita il nome del cliente e selezionalo dall'elenco che apparirà"/>
                    <b-button class="ml-2" variant="primary-light">Cerca</b-button>
                    </div>
                </b-form-group>
                <p>Ricerca cliente per Ragione Sociale (Azienda) o Nominativo.</p>
            </b-col>

            <b-col md="4">
                <div class="d-flex align-items-center mb-2 justify-content-end">
                    <div>
                        Cliente non presente?
                    </div>

                    <b-button class="ml-2" variant="primary-light">
                        Aggiungi Cliente
                    </b-button>
                </div>
            </b-col>
        </b-row>
      </div>
      </b-card>

      <b-card>
        <b-button variant="primary" @click="$router.push({name: 'seller_carfleet_estimate_create'})">
            Crea Preventivo
        </b-button>
      </b-card>
</section>

</template>

<script>
import {BCol, BRow, BButton, BCard, BBreadcrumb, BBreadcrumbItem, BFormInput, BFormGroup} from 'bootstrap-vue'

export default {
    components: {
        BCol,
        BRow,
        BButton,
        BCard,
        BBreadcrumb,
        BBreadcrumbItem,
        BFormInput,
        BFormGroup,
    }
}
</script>
